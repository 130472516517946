var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm","no-fade":""}},[_c('b-card-code',{attrs:{"title":"Profil"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Profesi","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Profesi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"placeholder":"Nama Profesi","size":"lg"},model:{value:(_vm.formProfil.id_profesi),callback:function ($$v) {_vm.$set(_vm.formProfil, "id_profesi", $$v)},expression:"formProfil.id_profesi"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Pilih Profesi")]),_vm._l((_vm.combo_profesi),function(option){return _c('option',{domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.nama_profesi)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"NIK","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"NIK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Masukkan NIK"},model:{value:(_vm.formProfil.nik),callback:function ($$v) {_vm.$set(_vm.formProfil, "nik", $$v)},expression:"formProfil.nik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"email","placeholder":"Masukkan Email"},model:{value:(_vm.formProfil.email),callback:function ($$v) {_vm.$set(_vm.formProfil, "email", $$v)},expression:"formProfil.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Masukkan Nama Lengkap"},model:{value:(_vm.formProfil.nama),callback:function ($$v) {_vm.$set(_vm.formProfil, "nama", $$v)},expression:"formProfil.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Jenis Kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"placeholder":"Jenis Kelamin","size":"lg"},model:{value:(_vm.formProfil.jenis_kelamin),callback:function ($$v) {_vm.$set(_vm.formProfil, "jenis_kelamin", $$v)},expression:"formProfil.jenis_kelamin"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Pilih Jenis Kelamin")]),_vm._l((_vm.combo_gender),function(option){return _c('option',{domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tempat Lahir","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Masukkan Tempat Lahir"},model:{value:(_vm.formProfil.tempat_lahir),callback:function ($$v) {_vm.$set(_vm.formProfil, "tempat_lahir", $$v)},expression:"formProfil.tempat_lahir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Tanggal Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Pilih Tanggal Lahir","config":_vm.configs.allowInput},model:{value:(_vm.formProfil.tanggal_lahir),callback:function ($$v) {_vm.$set(_vm.formProfil, "tanggal_lahir", $$v)},expression:"formProfil.tanggal_lahir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nomor Telepon","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Nomor Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Masukkan Nomor Telepon"},model:{value:(_vm.formProfil.hp),callback:function ($$v) {_vm.$set(_vm.formProfil, "hp", $$v)},expression:"formProfil.hp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Agama","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Agama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"placeholder":"Agama","size":"lg"},model:{value:(_vm.formProfil.agama),callback:function ($$v) {_vm.$set(_vm.formProfil, "agama", $$v)},expression:"formProfil.agama"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Pilih Agama")]),_vm._l((_vm.combo_agama),function(option){return _c('option',{domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kewarganegaraan","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Kewarganegaraan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"placeholder":"Kewarganegaraan","size":"lg"},model:{value:(_vm.formProfil.kewarganegaraan),callback:function ($$v) {_vm.$set(_vm.formProfil, "kewarganegaraan", $$v)},expression:"formProfil.kewarganegaraan"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Pilih Kewarganegaraan")]),_vm._l((_vm.combo_kewarganegaraan),function(option){return _c('option',{domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Pekerjaan","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Pekerjaan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Masukkan Pekerjaan"},model:{value:(_vm.formProfil.pekerjaan),callback:function ($$v) {_vm.$set(_vm.formProfil, "pekerjaan", $$v)},expression:"formProfil.pekerjaan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tempat Bekerja","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Tempat Bekerja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Masukkan Tempat Bekerja"},model:{value:(_vm.formProfil.tempat_bekerja),callback:function ($$v) {_vm.$set(_vm.formProfil, "tempat_bekerja", $$v)},expression:"formProfil.tempat_bekerja"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Pendidikan Formal","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Pendidikan Formal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"placeholder":"Pendidikan Formal","size":"lg"},model:{value:(_vm.formProfil.pendidikan_formal),callback:function ($$v) {_vm.$set(_vm.formProfil, "pendidikan_formal", $$v)},expression:"formProfil.pendidikan_formal"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Pilih Pendidikan Formal")]),_vm._l((_vm.combo_pendidikan_terakhir),function(option){return _c('option',{domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Alamat","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Masukkan Alamat","rows":"3"},model:{value:(_vm.formProfil.alamat),callback:function ($$v) {_vm.$set(_vm.formProfil, "alamat", $$v)},expression:"formProfil.alamat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(
              this.formProfil.id_profesi === 1 ||
              this.formProfil.id_profesi === 2 ||
              this.formProfil.id_profesi === 3 ||
              this.formProfil.id_profesi === 4
            )?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"PNS","label-for":"h-first-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"PNS","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"value":"1","plain":""},model:{value:(_vm.formProfil.is_pns),callback:function ($$v) {_vm.$set(_vm.formProfil, "is_pns", $$v)},expression:"formProfil.is_pns"}},[_vm._v(" Iya ")]),_c('b-form-checkbox',{attrs:{"value":"0","plain":""},model:{value:(_vm.formProfil.is_pns),callback:function ($$v) {_vm.$set(_vm.formProfil, "is_pns", $$v)},expression:"formProfil.is_pns"}},[_vm._v(" Tidak ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1882050017)})],1)],1):_vm._e(),(
              this.formProfil.is_pns === '1' || this.formProfil.is_pns === 1
            )?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Bekerja Di Instansi Lain","label-for":"h-first-name","label-cols-md":"2","rules":""}},[_c('validation-provider',{attrs:{"name":"is_instansi_lain"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"value":"true","plain":""},model:{value:(_vm.formProfil.is_instansi_lain),callback:function ($$v) {_vm.$set(_vm.formProfil, "is_instansi_lain", $$v)},expression:"formProfil.is_instansi_lain"}},[_vm._v(" Iya ")]),_c('b-form-checkbox',{attrs:{"value":"false","plain":""},model:{value:(_vm.formProfil.is_instansi_lain),callback:function ($$v) {_vm.$set(_vm.formProfil, "is_instansi_lain", $$v)},expression:"formProfil.is_instansi_lain"}},[_vm._v(" Tidak ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1967678955)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","align":"end"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitProfile.apply(null, arguments)}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }