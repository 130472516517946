smallsmallsmallsmallsmall
<template>
  <b-overlay :show="show" rounded="sm" no-fade>
    <b-card-code title="Profil">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Profesi"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Profesi"
                  rules="required"
                >
                  <b-form-select
                    placeholder="Nama Profesi"
                    v-model="formProfil.id_profesi"
                    size="lg"
                  >
                    <option :value="null" disabled>Pilih Profesi</option>
                    <option v-for="option in combo_profesi" :value="option.id">
                      {{ option.nama_profesi }}
                    </option>
                  </b-form-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="NIK"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NIK"
                  rules="required"
                >
                  <b-form-input
                    v-model="formProfil.nik"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukkan NIK"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Email"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="formProfil.email"
                    :state="errors.length > 0 ? false : null"
                    type="email"
                    placeholder="Masukkan Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Nama Lengkap"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Lengkap"
                  rules="required"
                >
                  <b-form-input
                    v-model="formProfil.nama"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukkan Nama Lengkap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Jenis Kelamin"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jenis Kelamin"
                  rules="required"
                >
                  <b-form-select
                    placeholder="Jenis Kelamin"
                    v-model="formProfil.jenis_kelamin"
                    size="lg"
                  >
                    <option :value="null" disabled>Pilih Jenis Kelamin</option>
                    <option v-for="option in combo_gender" :value="option">
                      {{ option }}
                    </option>
                  </b-form-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Tempat Lahir"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tempat Lahir"
                  rules="required"
                >
                  <b-form-input
                    v-model="formProfil.tempat_lahir"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukkan Tempat Lahir"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Tanggal Lahir"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Lahir"
                  rules="required"
                >
                  <flat-pickr
                    v-model="formProfil.tanggal_lahir"
                    class="form-control"
                    placeholder="Pilih Tanggal Lahir"
                    :config="configs.allowInput"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Nomor Telepon"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor Telepon"
                  rules="required"
                >
                  <b-form-input
                    v-model="formProfil.hp"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukkan Nomor Telepon"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Agama"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agama"
                  rules="required"
                >
                  <b-form-select
                    placeholder="Agama"
                    v-model="formProfil.agama"
                    size="lg"
                  >
                    <option :value="null" disabled>Pilih Agama</option>
                    <option v-for="option in combo_agama" :value="option">
                      {{ option }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Kewarganegaraan"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kewarganegaraan"
                  rules="required"
                >
                  <b-form-select
                    placeholder="Kewarganegaraan"
                    v-model="formProfil.kewarganegaraan"
                    size="lg"
                  >
                    <option :value="null" disabled>Pilih Kewarganegaraan</option>
                    <option v-for="option in combo_kewarganegaraan" :value="option">
                      {{ option }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Pekerjaan"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pekerjaan"
                  rules="required"
                >
                  <b-form-input
                    v-model="formProfil.pekerjaan"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukkan Pekerjaan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Tempat Bekerja"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tempat Bekerja"
                  rules="required"
                >
                  <b-form-input
                    v-model="formProfil.tempat_bekerja"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukkan Tempat Bekerja"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Pendidikan Formal"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pendidikan Formal"
                  rules="required"
                >
                  <b-form-select
                    placeholder="Pendidikan Formal"
                    v-model="formProfil.pendidikan_formal"
                    size="lg"
                  >
                    <option :value="null" disabled>Pilih Pendidikan Formal</option>
                    <option v-for="option in combo_pendidikan_terakhir" :value="option">
                      {{ option }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Alamat"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alamat"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="formProfil.alamat"
                    id="textarea-default"
                    placeholder="Masukkan Alamat"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              v-if="
                this.formProfil.id_profesi === 1 ||
                this.formProfil.id_profesi === 2 ||
                this.formProfil.id_profesi === 3 ||
                this.formProfil.id_profesi === 4
              "
            >
              <b-form-group
                label="PNS"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider #default="{ errors }" name="PNS" rules="">
                  <b-form-checkbox v-model="formProfil.is_pns" value="1" plain>
                    Iya
                  </b-form-checkbox>

                  <b-form-checkbox v-model="formProfil.is_pns" value="0" plain>
                    Tidak
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              v-if="
                this.formProfil.is_pns === '1' || this.formProfil.is_pns === 1
              "
            >
              <b-form-group
                label="Bekerja Di Instansi Lain"
                label-for="h-first-name"
                label-cols-md="2"
                rules=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="is_instansi_lain"
                >
                  <b-form-checkbox
                    v-model="formProfil.is_instansi_lain"
                    value="true"
                    plain
                  >
                    Iya
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="formProfil.is_instansi_lain"
                    value="false"
                    plain
                  >
                    Tidak
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" align="end">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="submitProfile"
              >
                Simpan
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
  </b-overlay>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import axios from "@axios";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    flatPickr,
  },
  data() {
    return {
      required,
      show: false,

      combo_profesi: [],

      combo_agama: [
        "ISLAM", "KRISTEN", "KATHOLIK", "HINDU", "BUDHA", "KHONG HUCU"
      ],

      combo_pendidikan_terakhir: [
        "TIDAK/BELUM SEKOLAH", "TIDAK TAMAT SD/ SEDERAJAT", "TAMAT SD/ SEDERAJAT", "SLTP/ SEDERAJAT", "SLTA/ SEDERAJAT", "DIPLOMA I/II", "AKADEMI/ DIPLOMA III/ S.MUDA", "DIPLOMA V/ STRATA I", "STRATA II", "STRATA III"
      ],

      combo_gender: ["pria", "wanita"],

      combo_kewarganegaraan: ["WARGA NEGARA INDONESIA", "WARGA NEGARA ASING"],

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "d-m-Y",
        },
      },

      formProfil: {
        alamat: "",
        email: "",
        hp: "",
        is_pns: 0,
        jenis_kelamin: null,
        nama: "",
        nik: "",
        status: "",
        tanggal_lahir: "",
        tempat_lahir: "",
        id_profesi: 0,
        is_instansi_lain: false,

        agama: null,
        kewarganegaraan: null,
        pekerjaan: "",
        pendidikan_formal: null,
      },
    };
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    getComboProfesi() {
      return new Promise(() => {
        axios
          .get("pendaftar/master_profesi")
          .then((res) => {
            this.combo_profesi = res.data;
            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    getData() {
      this.show = true;

      return new Promise(() => {
        axios
          .get(
            "pendaftar/get_profile?id_pendaftar=" +
              localStorage.getItem("id_pendaftar")
          )
          .then((res) => {
            this.formProfil = res.data[0];
            this.getComboProfesi();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    submitProfile() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          return new Promise(() => {
            axios
              .put(
                "pendaftar/update_profile?id_pendaftar=" +
                  localStorage.getItem("id_pendaftar"),
                this.formProfil
              )
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Update Profil Berhasil`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    text: `Update Profil Berhasil`,
                  },
                });
                this.getData();
                this.show = false;
              })
              .catch((error) => {
                if (error.response.status == 401) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                  this.show = false;
                  this.$router.push("/login");
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                  this.show = false;
                }
                this.show = false;
              });
          });
        } else {
          return;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
